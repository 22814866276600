import {postProductFavorite} from "../../services/products-service.js";

export const getFavoriteStatus = async (el) => {
    const favoriteButton = el.closest('.item-grid-favorite')

    if (!favoriteButton) return false

    const status = Number(favoriteButton.dataset.status),
        favorite = status ? 0 : 1

    favoriteButton.setAttribute('disabled', true)

    favoriteButton.setAttribute('data-status', favorite)

    await postProductFavorite({
        productId: el.closest('.item-grid').dataset.id
    }).then(e => {
        if (e['data']['status'] === 403) {
            window.location.href = e['data']['route']
        }
    }).catch(error => {
        //notificationError()
        favoriteButton.setAttribute('data-status', 0)
        console.log(error)
    })

    favoriteButton.setAttribute('disabled', false)
}
